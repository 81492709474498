<template>
<div>

    <s-toolbar dark search-input label="Configuración de Permisos">
    </s-toolbar>

    <v-row>
        <v-col cols="6">
            <s-crud title="Usuarios" height="350" :config="config" :filter="filter" @rowSelected="rowSelected($event)" sortable searchInput>
            </s-crud>
        </v-col>
        <v-col cols="6">

            <v-card elevation="0" outlined>
                <s-toolbar label="Permisos" search-input @removed="deleteEvent()" :remove="selectedPermit.length>0"></s-toolbar>
                <v-col>
                    <v-row justify="center">

                        <v-col cols=12>
                            <s-select-transaction v-model="TrsID" label="Tipo Circuito" :typeprocess="0" />
                        </v-col>

                    </v-row>
                    <v-row justify="center">
                        <v-col class="pt-1" lg=5>
                            <v-btn @click='TrsID=null' block small>Nuevo</v-btn>
                        </v-col>
                        <v-col class="pt-1" lg=5>
                            <v-btn @click=save(item) block small color="primary">Guardar</v-btn>
                        </v-col>
                    </v-row>

                </v-col>
                <v-data-table show-select :items=itemsPermit dense item-key="TrsID" :headers=this.configPermit.headers v-model=selectedPermit :single-select="true">
                </v-data-table>

            </v-card>
        </v-col>
    </v-row>

</div>
</template>

<script>
//Services
import _sUser from "@/services/Security/UserService";
import _sPermit from "@/services/Security/TransactionPermit";

export default {
    components: {

    },
    props: {},
    data() {
        return {
            TrsID: null,
            filter: {
                UsrStatus: 1,
                SecStatus: 1,
            },
            config: {
                model: {
                    UsrName: "string",
                    UsrID: "ID",
                    NtpID: "int",
                    NtpFullName: "string",
                    PrsDocumentNumber: "string",
                },
                service: _sUser,
                headers: [{
                        text: "ID",
                        value: "UsrID",
                        align: "end",
                    },
                    {
                        text: "Usuario",
                        value: "UsrName",
                    },
                    {
                        text: "Persona",
                        value: "NtpFullName"
                    },
                ]
            },

            configPermit: {
                headers: [{
                        text: "ID",
                        value: "TrsID",
                    },
                    {
                        text: "Transaccion",
                        value: "TrsName"
                    },
                ]
            },

            itemsPermit: [],
            selectedPermit: [],
        };
    },

    methods: {
        rowSelected(items) {

            if (items.length > 0) {
                this.item = items[0];
                console.log("item", this.item)
                _sPermit.byUser({
                    UsrID: this.item.UsrID
                }, this.$fun.getUserID()).then((r) => {
                    this.itemsPermit = r.data;
                    console.log("rowSelected", this.itemsPermit)
                })
            } else this.item = null;

        },

        deleteEvent() {
            if (this.selectedPermit.length > 0) {
                this.selectedPermit[0].SecStatus = 0;
                this.selectedPermit[0].UsrUpdateID = this.$fun.getUserID();

                _sPermit.save(this.selectedPermit[0], this.$fun.getUserID()).then((r) => {
                    _sPermit.byUser({
                        UsrID: this.item.UsrID
                    }, this.$fun.getUserID()).then((r) => {
                        this.itemsPermit = r.data;
                    })
                });
            }
        },
        save(item) {

            this.item.TrsID = this.TrsID
            this.item.UsrCreateID = this.$fun.getUserID();
            this.item.SecStatus = 1

            console.log("save", this.item)

            _sPermit.save(this.item, this.$fun.getUserID()).then((r) => {
                _sPermit.byUser({
                    UsrID: this.item.UsrID
                }, this.$fun.getUserID()).then((r) => {
                    this.itemsPermit = r.data;
                })
            });
        }
    },

};
</script>
